import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/layouts/legal.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><strong parentName="h1">{`Privacy Policy`}</strong></h1>
    <p>{`Effective January 20, 2023.`}</p>
    <p>{`This Privacy Policy describes how Sol Stores, Inc. and our subsidiaries
and corporate affiliates (collectively, the "`}<strong parentName="p">{`Company`}</strong>{`," "`}<strong parentName="p">{`we`}</strong>{`",
"`}<strong parentName="p">{`us`}</strong>{`" or "`}<strong parentName="p">{`our`}</strong>{`") handle personal information that we collect
through the following channels and as otherwise described in this
Privacy Policy:`}</p>
    <p>{`• our websites and other digital properties that link to this Privacy Policy (collectively, the "`}<strong parentName="p">{`Site`}</strong>{`");`}</p>
    <p>{`• our brick-and-mortar retail stores (if and when applicable) (together with the Site, the "`}<strong parentName="p">{`Service`}</strong>{`");`}</p>
    <p>{`• our marketing activities; and`}</p>
    <p>{`• our social media accounts and platforms.`}</p>
    <p><strong parentName="p">{`Table of Contents`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "#personal-information-we-collect"
        }}><strong parentName="a">{`Personal information we collect`}</strong></a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "#how-we-use-your-personal-information"
        }}><strong parentName="a">{`How we use your personal information`}</strong></a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "#how-we-share-your-personal-information"
        }}><strong parentName="a">{`How we share your personal information`}</strong></a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "#your-choices"
        }}><strong parentName="a">{`Your choices`}</strong></a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "#third-party-platforms"
        }}><strong parentName="a">{`Other sites and services`}</strong></a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "#security"
        }}><strong parentName="a">{`Security`}</strong></a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "#international-data-transfer"
        }}><strong parentName="a">{`International data transfer`}</strong></a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "#children"
        }}><strong parentName="a">{`Children`}</strong></a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "#changes-to-this-privacy-policy"
        }}><strong parentName="a">{`Changes to this Privacy Policy`}</strong></a></p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "#how-to-contact-us"
        }}><strong parentName="a">{`How to contact us`}</strong></a></p>
    </blockquote>
    <br />
    <div id='personal-information-we-collect' />
    <h1>{`Personal information we collect`}</h1>
    <p>{`• `}<strong parentName="p">{`Information you provide to us.`}</strong>{` Personal information you may provide to us through the Service or otherwise may include:`}</p>
    <p>{`• `}<strong parentName="p">{`Contact data`}</strong>{`, such as your first and last name, email address,
billing and mailing addresses, and phone number.`}</p>
    <p>{`• `}<strong parentName="p">{`Communications`}</strong>{` that we exchange, including when you contact us
with questions, feedback, or otherwise.`}</p>
    <p>{`• `}<strong parentName="p">{`Research data`}</strong>{` that you provide when you agree to participate in
our tutorials, surveys or research activities, such as your survey
responses.`}</p>
    <p>{`• `}<strong parentName="p">{`Transactional data,`}</strong>{` such as the information needed to complete
your orders on or through the Service (including name, credit card
information, bank account number, cryptocurrency wallet address, billing and
shipping information), and your purchase history.`}</p>
    <p>{`• `}<strong parentName="p">{`Marketing data`}</strong>{`, such as your preferences for receiving
communications about products sold through our Service, services,
events, promotions and publications, and details about how you
engage with our communications.`}</p>
    <p>{`• `}<strong parentName="p">{`Content`}</strong>{` that you post or upload to areas of the Service visible
to other users, such as product reviews or comments.`}</p>
    <p>{`• `}<strong parentName="p">{`Other information`}</strong>{` that we may collect which is not specifically
listed here, which we will use as described in this Privacy Policy
or as otherwise disclosed at the time of collection.`}</p>
    <p>{`• `}<strong parentName="p">{`Third party sources.`}</strong>{` We may combine personal information we receive from you with personal information we obtain from other sources, such as:`}</p>
    <p>{`• `}<strong parentName="p">{`Publicly-available sources`}</strong>{`, such as social media platforms.`}</p>
    <p>{`• `}<strong parentName="p">{`Business partners`}</strong>{`, such as our brand partners who may sell their
products and/or services through the Service, our joint marketing
partners and our event co-sponsors.`}</p>
    <p>{`• `}<strong parentName="p">{`Automatic data collection.`}</strong>{` We, our service providers, and our business partners may automatically log information about you, your computer or mobile device, and your interactions over time with our Service and other sites and online services, such as:`}</p>
    <p>{`• `}<strong parentName="p">{`Device data`}</strong>{`, such as your computer or mobile device operating
system type and version number, manufacturer and model, browser
type, screen resolution, IP address, IP address, unique identifiers,
the website you visited before browsing to our website, general
location information (such as city, state or geographic area) and
precise geolocation (when you give us permission to collect it in
your device's settings).`}</p>
    <p>{`• `}<strong parentName="p">{`Online activity data`}</strong>{`, such as pages or screens you viewed, how
long you spent on a page or screen, navigation paths between pages
or screens, information about your activity on a page or screen,
access times, and duration of access.`}</p>
    <p>{`• `}<strong parentName="p">{`In-store sensor data,`}</strong>{` such as information collected about
visitors to our stores from video cameras, smartphone detectors,
Wi-Fi networks, and other devices.`}</p>
    <p>{`• `}<strong parentName="p">{`Security camera footage`}</strong>{` from our stores.`}</p>
    <p>{`• `}<strong parentName="p">{`Cookies and similar technologies`}</strong>{`. Like many online services, we use
the following technologies:`}</p>
    <p>{`• `}<strong parentName="p">{`Cookies`}</strong>{`, which are text files that websites store on a visitor's
device to uniquely identify the visitor's browser or to store
information or settings in the browser for the purpose of helping
you navigate between pages efficiently, remembering your
preferences, enabling functionality, helping us understand user
activity and patterns, and facilitating online advertising.`}</p>
    <p>{`• `}<strong parentName="p">{`Locally stored objects`}</strong>{`, which are used on websites for purposes
similar to cookies but allow storage of a larger amount of data.`}</p>
    <p>{`• `}<strong parentName="p">{`Information about others.`}</strong>{` Users of the Service have the opportunity
to share contact information of others with us. Please do not do so
unless you have their permission.`}</p>
    <div id='how-we-use-your-personal-information' />
    <h1>{`How we use your personal information`}</h1>
    <p>{`We use your personal information for the following purposes or as
otherwise described at the time we collect it:`}</p>
    <p><strong parentName="p">{`Service delivery.`}</strong>{` We may use your personal information to:`}</p>
    <pre><code parentName="pre" {...{}}>{`• provide, operate and improve the Service and our business;

• fulfill the orders you place through the Service;

• communicate with you about the Service, including by sending
    announcements, updates, security alerts, and support and
    administrative messages;

• understand your needs and interests, and personalize your experience
    with the Service and our communications; and

• provide support for the Service, respond to your requests, questions
    and feedback.

• allow us and our loyalty benefits program partners (“Loyalty Program Partners”) to deliver such loyalty benefits that we or they may offer, such as a digital asset (e.g. a proof of attendance protocol non-fungible token (“NFT”) or discount or other NFTs) delivered to your cryptocurrency wallet address or physical item (e.g. baseball cap) delivered to your provided mailing address.
`}</code></pre>
    <br />
    <p><strong parentName="p">{`Research and development.`}</strong>{` We may use your personal information for research and development purposes, including to analyze and improve the Service and our business. As part of these activities, we may create aggregated, de-identified or other anonymous data from personal information we collect. We make personal information into anonymous data by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve the Service and promote our business. `}</p>
    <p><strong parentName="p">{`Marketing and advertising.`}</strong>{` We and our third party advertising partners, if any, may collect and use your personal information for marketing and advertising purposes:`}</p>
    <p>{`• Direct marketing. We may send you Company-related or other direct marketing communications as permitted by law, including by email, mail and/or NFT. You may opt-out of our marketing communications as described in the `}<a parentName="p" {...{
        "href": "#bookmark=id.49x2ik5"
      }}>{`Opt-out of marketing`}</a>{` section below. `}</p>
    <p>{`• Testimonials and feedback. With their consent, we may publish testimonials and comments from users and identify them by their name or city. `}</p>
    <p><strong parentName="p">{`Compliance and protection.`}</strong>{` We may use your personal information to:`}</p>
    <p>{`• comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities;`}</p>
    <p>{`• protect our, your or others' rights, privacy, safety, security or property (including by making and defending legal claims and security monitoring in our stores); `}</p>
    <p>{`• audit our internal processes for compliance with legal and contractual requirements and internal policies; `}</p>
    <p>{`• enforce the terms and conditions that govern the Service; and `}</p>
    <p>{`• prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft. `}</p>
    <div id='how-we-share-your-personal-information' />
    <h1>{`How we share your personal information`}</h1>
    <p>{`We may share your personal information with the following parties and as
otherwise described in this Privacy Policy or at the time of collection:`}</p>
    <p><strong parentName="p">{`Subsidiaries and Affiliates.`}</strong>{` Our subsidiaries and corporate
affiliates for purposes consistent with this Privacy Policy.`}</p>
    <p><strong parentName="p">{`Service providers.`}</strong>{` Companies and individuals that provide services
on our behalf or help us operate the Service or our business (such as
IT, hosting, customer relationship management and support, payment
processing, print and mail fulfillment, order fulfillment and shipping,
data management, email delivery, marketing, research, website analytics,
in-store sensor analytics, event management services, promotions,
sweepstakes, contests, tutorials and surveys).`}</p>
    <p><strong parentName="p">{`Business partners.`}</strong>{` Business partners, including event co-sponsors,
companies with whom we jointly-market products and services, and brands
and retailers that market and sell their products in our stores and on
our Site, who may collect your information directly through our Service
or with whom we may share your information as described to you when it
is collected. Our business partners' use of your personal information is
governed by their own privacy policies.`}</p>
    <p><strong parentName="p">{`Loyalty Program Partners.`}</strong>{` Loyalty Program Partners, with whom we may share your cryptocurrency wallet address and/or mailing address in order to allow for your participation in their loyalty programs, including the delivery of loyalty benefit items. Our Loyalty Program Partners’ use of your personal information is governed by their own privacy policies.`}</p>
    <p><strong parentName="p">{`Other users and the public.`}</strong>{` Other parties who can view your product
reviews, comments or other content on areas of the Service accessible to
other users and the public, where it can be copied and retained even
after you modify or delete it.`}</p>
    <p><strong parentName="p">{`Professional advisors.`}</strong>{` Professional advisors, such as lawyers,
auditors, bankers and insurers, where necessary in the course of the
professional services that they render to us.`}</p>
    <p><strong parentName="p">{`Authorities and others.`}</strong>{` Law enforcement, government authorities, and
private parties, as we believe in good faith to be necessary or
appropriate for the compliance and operations
purposes described above.`}</p>
    <p><strong parentName="p">{`Business transferees.`}</strong>{` Relevant participants in business transactions
(or potential transactions) involving a corporate divestiture, merger,
consolidation, acquisition, reorganization, sale or other disposition of
all or any portion of the business or assets of, or equity interests in,
the Company or our affiliates (including, in connection with a
bankruptcy or similar proceedings).`}</p>
    <div id='your-choices' />
    <h1>{`Your choices`}</h1>
    <p>{`You have the following choices with respect to your personal
information.`}</p>
    <p><strong parentName="p">{`Opt-out of marketing communications.`}</strong>{` You may opt out of marketing-related emails and other communications by following the opt-out or unsubscribe instructions at the bottom of the email, or by emailing us at `}<a parentName="p" {...{
        "href": "mailto:marketing@solanaspaces.com."
      }}>{`marketing@solanaspaces.com.`}</a>{` You may continue to receive service-related and other non-marketing emails and communications. `}</p>
    <p><strong parentName="p">{`Cookies.`}</strong>{` Most browsers let you remove and/or stop accepting cookies
from the websites you visit. To do this, follow the instructions in your
browser's settings. Many browsers accept cookies by default until you
change your settings. If you do not accept cookies, however, you may not
be able to use all functionality of the Service and our Site may not
work properly. For more information about cookies, including how to see
what cookies have been set on your browser and how to manage and delete
them, visit `}<a parentName="p" {...{
        "href": "http://www.allaboutcookies.org."
      }}>{`http://www.allaboutcookies.org.`}</a>{` We may
use Google Analytics to help us understand user activity and patters on
the Site. You can learn more about Google Analytics cookies at
`}<a parentName="p" {...{
        "href": "https://developers.google.com/analytics/resources/concepts/gaconceptscookies"
      }}>{`https://developers.google.com/analytics/resources/concepts/gaconceptscookies`}</a>{`
and about how Google protects your data at
`}<a parentName="p" {...{
        "href": "http://www.google.com/analytics/learn/privacy.html"
      }}>{`http://www.google.com/analytics/learn/privacy.html`}</a>{`. You can prevent
the use of Google Analytics relating to your use of our Site by
downloading and installing a browser plugin available at
`}<a parentName="p" {...{
        "href": "https://tools.google.com/DLPAGE/GAOPTOUT?HL=EN"
      }}>{`https://tools.google.com/DLPAGE/GAOPTOUT?HL=EN`}</a>{`.
Your web browser may provide functionality to clear locally stored
objects in your browser storage.`}</p>
    <p><strong parentName="p">{`Declining to provide information.`}</strong>{` We need to collect personal information to provide certain Services. If you do not provide the information requested, we may not be able to provide those Services.`}</p>
    <div id='third-party-platforms' />
    <h1>{`Third party platforms.`}</h1>
    <p>{`If you choose to connect to the Service through your social media account such as Facebook, you may be able to use your settings in your account with that platform to limit the information we receive from it. If you revoke our ability to access information from a third party platform, that choice will not apply to information that we have already received from that third party.`}</p>
    <h1>{`Other sites and services`}</h1>
    <p>{`The Service may contain links to websites, mobile applications, and other online services operated by third parties. These links are not an endorsement of, or representation that we are affiliated with, any third party. In addition, our content may be included on web pages or in mobile applications or other online services that are not associated with us. We do not control websites, mobile applications or online services operated by third parties, and we are not responsible for their actions. `}</p>
    <div id='security' />
    <h1>{`Security`}</h1>
    <p>{`We employ a number of technical, organizational and physical safeguards designed to protect the personal information we collect. However, security risk is inherent in all internet and information technologies and we cannot guarantee the security of your personal information. `}</p>
    <div id='international-data-transfer' />
    <h1>{`International data transfer`}</h1>
    <p>{`We are headquartered in the United States and may use service providers that operate in other countries. Your personal information may be transferred to the United States or other locations where privacy laws may not be as protective as those in your state, province, or country. `}</p>
    <div id='children' />
    <h1>{`Children`}</h1>
    <p>{`The Service is not intended for use by children under 16 years of age. If we learn that we have collected personal information from a child under 16 without the consent of the child's parent or guardian as required by law, we will delete it. `}</p>
    <div id='changes-to-this-privacy-policy' />
    <h1>{`Changes to this Privacy Policy`}</h1>
    <p>{`We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by email to the email address you provided to the website and/or by updating the date of this Privacy Policy at the top of this page and posting it on the Service. Any modifications to this Privacy Policy will be effective upon our posting the modified version (or as otherwise indicated at the time of posting). In all cases, your continued use of the Service after the posting of any modified Privacy Policy indicates your acceptance of the modified Privacy Policy.`}</p>
    <div id='how-to-contact-us' />
    <h1>{`How to contact us`}</h1>
    <p>{`You can reach us by email at
`}<a parentName="p" {...{
        "href": "mailto:privacy@solanaspaces.com"
      }}>{`[privacy@solanaspaces.com]`}</a>{` and
by mail at Sol Stores, Inc., Attention: General Counsel, 548 Market
Street PMB 45477, San Francisco, CA 94104.`}</p>
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      